import { Box, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../utils/Colors';

const OurServicesCard = (props) => {
    const data = props.data;
    return (
        <Box
            sx={{
                width: {
                    xl: '250px',
                    lg: '250px',
                    md: '210px',
                    sm: '100%',
                    xs: '100%',
                },
                height: '350px',
                border: '1px solid grey',
                borderRadius: '10px',
                position: 'relative',
                overflow: 'hidden', // Hide overflow to prevent the image from overflowing the border
            }}
        >
            <Box
                component={'img'}
                src={data.image}
                alt={
                    data.image &&
                    data.image.substring(data.image.lastIndexOf('/') + 1)
                }
                sx={{
                    width: '100%',
                    height: '300px',
                    borderRadius: '10px',
                    zIndex: 2,
                    objectFit: 'cover',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    boxSizing: 'border-box',
                    zIndex: 3,
                    background: 'rgba(0, 0, 0, 0.5)',
                    color: Colors.WHITE,
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        padding: 1,
                    }}
                >
                    {data.name}
                </Typography>
            </Box>
        </Box>
    );
};

export default OurServicesCard;
