import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../utils/Colors';
import JobCard from '../../components/JobCard/JobCard';
import { getObjects } from '../../api/Api';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import JobLoading from '../../components/SkeletonLoading/JobLoading/JobLoading';
import { MetaTags } from 'react-meta-tags';
import NotFoundData from '../../components/NoteFoundData/NotFoundData';

const Job = () => {
    const { data, isError, isLoading, isSuccess } = useQuery(['jobs'], () =>
        getObjects('vacancy')
    );
    if (isError) {
        return <Typography>Occurred an error</Typography>;
    }
    if (isLoading) {
        return <JobLoading />;
    }
    if (isSuccess) {
        return (
            <Box
                sx={{
                    backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                    minHeight: '1000px',
                }}
            >
                <MetaTags>
                    <title>Afghan cosmos - Job</title>
                    <meta name="description" content="Brows our Job page" />
                </MetaTags>
                {data.length === 0 ? (
                    <NotFoundData type="job" />
                ) : (
                    <Container>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                mb: 4,
                            }}
                        >
                            <Typography
                                variant="h3"
                                mt={6}
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: {
                                        xl: '30px',
                                        lg: '30px',
                                        md: '25px',
                                        sm: '20px',
                                        xs: '20px',
                                    },
                                    textAlign: 'left',
                                }}
                            >
                                Our Latest
                                <span
                                    style={{
                                        color: Colors.PRIMARY,
                                        fontWeight: 1000,
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                    }}
                                >
                                    Jobs
                                </span>
                            </Typography>
                        </Box>
                        <Grid container spacing={4}>
                            <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    {data.map((item, index) => (
                                        <Link
                                            key={index}
                                            to={`/job/details/${item.id}/`}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <JobCard data={item} />
                                        </Link>
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                                <Paper
                                    sx={{
                                        width: '100%',
                                        height: '250px',
                                        border: '1px solid lightGrey',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2,
                                            p: 2,
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                variant="h6"
                                                fontWeight={'bold'}
                                            >
                                                Our top services
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                )}
            </Box>
        );
    }
};

export default Job;
