import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../utils/Colors';
import { useQuery } from '@tanstack/react-query';
import { getObjects } from '../../api/Api';
import ServiceProfileCard from '../../components/ServiceProfileCard/ServiceProfileCard';
import ServiceLoading from '../../components/SkeletonLoading/ServiceLoading';
import { MetaTags } from 'react-meta-tags';

const Service = () => {
    const { data, isError, isLoading, isSuccess } = useQuery(['service'], () =>
        getObjects('service')
    );
    if (isError) {
        return <Typography>Occurred an error</Typography>;
    }
    if (isLoading) {
        return <ServiceLoading />;
    }
    if (isSuccess) {
        return (
            <Box>
                <MetaTags>
                    <title>Afghan cosmos - Service</title>
                    <meta name="description" content="Brows our service page" />
                </MetaTags>
                <Box
                    sx={{
                        height: '400px',
                        backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            width: { xl: '50%', lg: '50%', xs: '80%' },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 3,
                        }}
                    >
                        <Typography
                            variant="h4"
                            fontWeight={'bold'}
                            sx={{
                                textAlign: 'center',
                                color: Colors.PRIMARY,
                                fontSize: { xl: '45px', xs: '30px' },
                            }}
                        >
                            We are Here to help your Business
                        </Typography>
                        <Typography sx={{ textAlign: 'center' }}>
                            Our services solve any business problem
                        </Typography>
                    </Box>
                </Box>
                <Container sx={{ mt: 5 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="h4">
                            Our{' '}
                            <span style={{ color: Colors.PRIMARY }}>
                                Services
                            </span>
                        </Typography>
                    </Box>
                    <Grid container columnSpacing={2} rowSpacing={3} mt={3}>
                        {data.map((item, index) => (
                            <Grid
                                item
                                key={index}
                                xl={4}
                                lg={4}
                                md={4}
                                sm={6}
                                xs={12}
                            >
                                <ServiceProfileCard data={item} />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        );
    }
};

export default Service;
