import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Colors from '../../../utils/Colors';

const BlogDetailsLoading = () => {
    return (
        <Box
            sx={{
                minHeight: '1000px',
                backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
            }}
        >
            <Container>
                <Box>
                    <Button
                        sx={{ mt: 10, mb: 10 }}
                        startIcon={<KeyboardBackspaceIcon sx={{ mr: 1 }} />}
                    >
                        <Typography>Loading...</Typography>
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: 5,
                    }}
                >
                    <Box
                        sx={{
                            width: {
                                xl: '70%',
                                lg: '70%',
                                md: '80%',
                                sm: '90%',
                                xs: '90%',
                            },
                        }}
                    >
                        <Skeleton variant="text" height={40} />
                    </Box>
                    <Box
                        sx={{
                            height: {
                                xl: '500px',
                                lg: '500px',
                                md: '400px',
                                sm: '300px',
                                xs: '200px',
                            },
                        }}
                    >
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="100%"
                        />
                    </Box>
                    <Box
                        sx={{
                            width: {
                                xl: '70%',
                                lg: '70%',
                                md: '80%',
                                sm: '90%',
                                xs: '90%',
                            },
                        }}
                    >
                        <Skeleton variant="text" height={200} />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default BlogDetailsLoading;
