import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Colors from '../../utils/Colors';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const ScholarshipCart = (props) => {
    const data = props.data;
    const phoneNumber = '+93749999994';
    return (
        <Paper
            sx={{
                minHeight: '320px',
                mt: { xl: 2, lg: 2, md: 2, sm: 4, xs: 4 },
            }}
        >
            <Grid container>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <img
                        src={data.image}
                        alt={
                            data.image &&
                            data.image.substring(
                                data.image.lastIndexOf('/') + 1
                            )
                        }
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '10px',
                            objectFit: 'cover',
                        }}
                    />
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            minHeight: '350px',
                            p: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}
                        >
                            <Typography
                                variant="h6"
                                fontWeight={'bold'}
                                sx={{ fontSize: { xs: '17px' } }}
                            >
                                {data.title}
                            </Typography>
                            <Box
                                sx={{ display: 'flex', gap: 1, color: 'grey' }}
                            >
                                <LocationOnIcon />
                                <Typography>{data.location}</Typography>
                            </Box>
                            <Typography sx={{ fontSize: { xs: '13px' } }}>
                                {data.description}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                height: '50px',
                                border: '1px solid lightGrey',
                                borderRadius: '10px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 1,
                                mt: { xl: 8, lg: 8, md: 6, sm: 4, xs: 4 },
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 1,
                                    borderRight: '1px solid lightGrey',
                                    alignItems: 'center',
                                }}
                            >
                                <KeyboardArrowRightIcon />
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xl: '10px',
                                            lg: '10px',
                                            md: '10px',
                                            sm: '10px',
                                            xs: '10px',
                                        },
                                        pr: 1,
                                    }}
                                >
                                    {data.type}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 1,
                                    borderRight: '1px solid lightGrey',
                                    alignItems: 'center',
                                }}
                            >
                                <WatchLaterIcon />
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xl: '10px',
                                            lg: '10px',
                                            md: '10px',
                                            sm: '10px',
                                            xs: '10px',
                                        },
                                        pr: 1,
                                    }}
                                >
                                    published: {data.publishedDate}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 1,
                                    alignItems: 'center',
                                }}
                            >
                                <CalendarMonthIcon />
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xl: '10px',
                                            lg: '10px',
                                            md: '10px',
                                            sm: '10px',
                                            xs: '10px',
                                        },
                                    }}
                                >
                                    deadline: {data.deadline}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Box
                        sx={{
                            minHeight: '360px',
                            backgroundColor: Colors.BACKGROUND_CARD,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    color: Colors.PRIMARY,
                                    fontWeight: 'bold',
                                }}
                            >
                                Call Us Now
                            </Typography>
                            <Typography variant="body2">
                                for more info
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 3,
                            }}
                        >
                            <Box
                                component={'a'}
                                href={`tel:${phoneNumber}`}
                                target="_blank"
                            >
                                <PhoneIcon sx={{ color: Colors.BLACK }} />
                            </Box>
                            <Box>
                                <PersonIcon />
                            </Box>
                            <Box
                                component={'a'}
                                href={`https://wa.me/${phoneNumber}/`}
                                target="_blank"
                            >
                                <WhatsAppIcon sx={{ color: Colors.BLACK }} />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ScholarshipCart;
