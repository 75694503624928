import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../utils/Colors';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getObject } from '../../api/Api';
import BlogDetailsLoading from '../../components/SkeletonLoading/BlogLoading/BlogDetailsLoading';
import YoutubeVideo from '../../components/YoutubeVideo/YoutubeVideo';
import ShareIcon from '@mui/icons-material/Share';
import { MetaTags } from 'react-meta-tags';

const EventDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    };
    const handleShare = () => {
        // Share on Facebook
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            window.location.href
        )}`;
        window.open(facebookShareUrl, '_blank');
    };
    const { data, isError, isLoading, isSuccess } = useQuery(
        ['event-details'],
        () => {
            return getObject('event', id);
        }
    );

    if (isError) {
        return <Typography>Occurred an Error</Typography>;
    }

    if (isLoading) {
        return <BlogDetailsLoading />;
    }

    if (isSuccess) {
        return (
            <Box
                sx={{
                    minHeight: '1000px',
                    backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                }}
            >
                <MetaTags>
                    <title>Afghan cosmos - Event details</title>
                    <meta
                        name="description"
                        content="Brows our event details"
                    />
                    <meta
                        name="keywords"
                        content="afghan cosmos, afghan cosmos event, event, event details, famous event, events details"
                    />
                </MetaTags>
                <Container>
                    <Box>
                        <Button
                            onClick={() => back()}
                            sx={{ mt: 10, mb: 10 }}
                            startIcon={<KeyboardBackspaceIcon sx={{ mr: 1 }} />}
                        >
                            <Typography>Back</Typography>
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: 5,
                        }}
                    >
                        <Box
                            sx={{
                                width: {
                                    xl: '70%',
                                    lg: '70%',
                                    md: '80%',
                                    sm: '90%',
                                    xs: '90%',
                                },
                            }}
                        >
                            <Typography
                                variant="h4"
                                fontWeight={'bold'}
                                sx={{
                                    fontSize: {
                                        xl: '30px',
                                        lg: '30px',
                                        md: '25px',
                                        sm: '20px',
                                        xs: '19px',
                                    },
                                }}
                            >
                                {data.title}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography variant="body2" mt={2}>
                                    {data.date}
                                </Typography>

                                <IconButton onClick={handleShare}>
                                    <ShareIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box
                            component={'img'}
                            src={data.images[0].image}
                            alt={
                                data.images[0].image &&
                                data.images[0].image.substring(
                                    data.images[0].image.lastIndexOf('/') + 1
                                )
                            }
                            sx={{
                                width: '100%',
                                height: {
                                    xl: '600px',
                                    lg: '600px',
                                    md: '500px',
                                    sm: '300px',
                                    xs: '200px',
                                },
                                borderRadius: '10px',
                            }}
                        />
                        <Box
                            sx={{
                                width: {
                                    xl: '70%',
                                    lg: '70%',
                                    md: '80%',
                                    sm: '90%',
                                    xs: '90%',
                                },
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data.description,
                                }}
                            />
                        </Box>
                        <Box>
                            {data.youtube_link && (
                                <YoutubeVideo videoLink={data.youtube_link} />
                            )}  
                        </Box>
                    </Box>
                </Container>
            </Box>
        );
    }
};

export default EventDetails;
