import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Colors from '../../utils/Colors';
import {
    Box,
    Breadcrumbs,
    Button,
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SchoolIcon from '@mui/icons-material/School';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import UploadIcon from '@mui/icons-material/Upload';
import { useQuery } from '@tanstack/react-query';
import { getObject } from '../../api/Api';
import JobDetailsLoading from '../../components/SkeletonLoading/JobLoading/JobDetailsLoading';
import { MetaTags } from 'react-meta-tags';

const JobDetails = () => {
    const { id } = useParams();
    const { data, isError, isLoading, isSuccess } = useQuery(
        ['job-details'],
        () => {
            return getObject('vacancy', id);
        }
    );

    if (isError) {
        return <Typography>Occurred an Error</Typography>;
    }

    if (isLoading) {
        return <JobDetailsLoading />;
    }

    if (isSuccess) {
        return (
            <Box
                sx={{
                    backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                    minHeight: '800px',
                }}
            >
                <MetaTags>
                    <title>Afghan cosmos - Job details</title>
                    <meta
                        name="description"
                        content="Brows our job details page"
                    />
                </MetaTags>
                <Container>
                    <Box>
                        <Breadcrumbs sx={{ pt: 10 }}>
                            <Link
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: Colors.PRIMARY,
                                    textDecoration: 'none',
                                    gap: 5,
                                }}
                                to={'/job/'}
                            >
                                <KeyboardBackspaceIcon
                                    sx={{ mr: 1 }}
                                    fontSize="inherit"
                                />
                                See All Jobs
                            </Link>
                        </Breadcrumbs>
                    </Box>
                    <Box mt={5}>
                        <Paper
                            sx={{
                                width: '100%',
                                minHeight: '900px',
                                borderRadius: '10px',
                                position: 'relative',
                            }}
                        >
                            <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '82px',
                                    height: '82px',
                                    borderRadius: '50%',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    left: {
                                        xl: '46%',
                                        lg: '46%',
                                        md: '46%',
                                        sm: '46%',
                                        xs: '41%',
                                    },
                                    top: -30,
                                }}
                            >
                                <img
                                    src={data.service.image}
                                    alt={
                                        data.service.image &&
                                        data.service.image.substring(
                                            data.service.image.lastIndexOf(
                                                '/'
                                            ) + 1
                                        )
                                    }
                                    style={{
                                        width: '80px',
                                        height: '80px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </Paper>
                            <Container>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        pt: 10,
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        fontWeight={'bold'}
                                    >
                                        {data.title}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        pt: 1,
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        fontWeight={'bold'}
                                    >
                                        Afghan Cosmos
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        minHeight: '90px',
                                        borderTop: '0.2px solid lightGrey',
                                        borderBottom: '0.2px solid lightGrey',
                                        display: 'flex',
                                        flexDirection: {
                                            xl: 'row',
                                            lg: 'row',
                                            md: 'row',
                                            sm: 'row',
                                            xs: 'column',
                                        },
                                        alignItems: {
                                            xl: 'center',
                                            lg: 'center',
                                            md: 'center',
                                            sm: 'center',
                                            xs: 'start',
                                        },
                                        gap: {
                                            xl: 4,
                                            lg: 4,
                                            md: 4,
                                            sm: 4,
                                            xs: 2,
                                        },
                                        mt: 5,
                                        pb: {
                                            xl: 0,
                                            lg: 0,
                                            md: 0,
                                            sm: 0,
                                            xs: 3,
                                        },
                                        pt: {
                                            xl: 0,
                                            lg: 0,
                                            md: 0,
                                            sm: 0,
                                            xs: 3,
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        <SchoolIcon sx={{ color: 'grey' }} />
                                        <Typography
                                            sx={{
                                                color: 'grey',
                                                fontSize: {
                                                    xl: '18px',
                                                    lg: '18px',
                                                    md: '16px',
                                                    sm: '14px',
                                                    xs: '12px',
                                                },
                                            }}
                                        >
                                            {data.minimumEducation} Degree
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        <LocationOnIcon
                                            sx={{ color: 'grey' }}
                                        />
                                        <Typography
                                            sx={{
                                                color: 'grey',
                                                fontSize: {
                                                    xl: '18px',
                                                    lg: '18px',
                                                    md: '16px',
                                                    sm: '14px',
                                                    xs: '12px',
                                                },
                                            }}
                                        >
                                            Kabul Shahr-e New
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        <AccessTimeFilledIcon
                                            sx={{ color: 'grey' }}
                                        />
                                        <Typography
                                            sx={{
                                                color: 'grey',
                                                fontSize: {
                                                    xl: '18px',
                                                    lg: '18px',
                                                    md: '16px',
                                                    sm: '14px',
                                                    xs: '12px',
                                                },
                                            }}
                                        >
                                            Full Time
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box mt={4}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xl={8}
                                            lg={8}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            sx={{
                                                order: {
                                                    xl: 1,
                                                    lg: 1,
                                                    md: 1,
                                                    sm: 1,
                                                    xs: 2,
                                                },
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 4,
                                                }}
                                            >
                                                <Box>
                                                    <Typography
                                                        fontWeight={'bold'}
                                                        variant="h5"
                                                    >
                                                        Job Summary
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        mt={2}
                                                        sx={{ width: '90%' }}
                                                    >
                                                        {data.jobSummary}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        fontWeight={'bold'}
                                                        variant="h5"
                                                    >
                                                        Key function and
                                                        requirement
                                                    </Typography>
                                                    <List
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 360,
                                                        }}
                                                    >
                                                        {data.KeyFunctionAndRequirementVacancy.map(
                                                            (item, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            item.title
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            )
                                                        )}
                                                    </List>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        fontWeight={'bold'}
                                                        variant="h5"
                                                    >
                                                        Main Function
                                                    </Typography>
                                                    <List
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 360,
                                                        }}
                                                    >
                                                        {data.MainFunctionVacancy.map(
                                                            (item, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            item.title
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            )
                                                        )}
                                                    </List>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        fontWeight={'bold'}
                                                        variant="h5"
                                                    >
                                                        Essential requirement
                                                    </Typography>
                                                    <List
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 360,
                                                        }}
                                                    >
                                                        {data.EssentialRequirementVacancy.map(
                                                            (item, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            item.title
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            )
                                                        )}
                                                    </List>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        fontWeight={'bold'}
                                                        variant="h5"
                                                    >
                                                        Desirable requirement
                                                    </Typography>
                                                    <List
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 360,
                                                        }}
                                                    >
                                                        {data.DesirableRequirementVacancy.map(
                                                            (item, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            item.title
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            )
                                                        )}
                                                    </List>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        fontWeight={'bold'}
                                                        variant="h5"
                                                    >
                                                        Aspect of the work
                                                        environment
                                                    </Typography>
                                                    <List
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 360,
                                                        }}
                                                    >
                                                        {data.AspectVacancy.map(
                                                            (item, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            item.title
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            )
                                                        )}
                                                    </List>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        fontWeight={'bold'}
                                                        variant="h5"
                                                    >
                                                        Desirable competency
                                                    </Typography>
                                                    <List
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 360,
                                                        }}
                                                    >
                                                        {data.DesirableCompetencyVacancy.map(
                                                            (item, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            item.title
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            )
                                                        )}
                                                    </List>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        fontWeight={'bold'}
                                                        variant="h5"
                                                    >
                                                        Desirable skill
                                                    </Typography>
                                                    <List
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 360,
                                                        }}
                                                    >
                                                        {data.DesirableSkillVacancy.map(
                                                            (item, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            item.title
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            )
                                                        )}
                                                    </List>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        fontWeight={'bold'}
                                                        variant="h5"
                                                    >
                                                        Contribution
                                                    </Typography>
                                                    <List
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 360,
                                                        }}
                                                    >
                                                        {data.ContributionVacancy.map(
                                                            (item, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            item.name
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            )
                                                        )}
                                                    </List>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        fontWeight={'bold'}
                                                        variant="h5"
                                                    >
                                                        Expected result
                                                    </Typography>
                                                    <List
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 360,
                                                        }}
                                                    >
                                                        {data.ExpectedResultVacancy.map(
                                                            (item, index) => (
                                                                <ListItem
                                                                    key={index}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            item.title
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            )
                                                        )}
                                                    </List>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        fontWeight={'bold'}
                                                        variant="h5"
                                                    >
                                                        Submission Guideline
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        mt={2}
                                                        sx={{ width: '90%' }}
                                                    >
                                                        Interested candidates
                                                        should fill out and
                                                        submit the APPLICATION
                                                        FORM with complete and
                                                        correct information. To
                                                        have access to the
                                                        APPLICATION FORM.
                                                    </Typography>
                                                </Box>
                                                <Box mb={4}>
                                                    <Link
                                                        to={'/job/apply/'}
                                                        style={{
                                                            textDecoration:
                                                                'none',
                                                        }}
                                                    >
                                                        <Link
                                                            to={`/job/apply/${data.id}/`}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                endIcon={
                                                                    <UploadIcon />
                                                                }
                                                                sx={{
                                                                    borderRadius:
                                                                        '20px',
                                                                    height: '40px',
                                                                }}
                                                            >
                                                                Apply Now
                                                            </Button>
                                                        </Link>
                                                    </Link>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={4}
                                            lg={4}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            sx={{
                                                order: {
                                                    xl: 2,
                                                    lg: 2,
                                                    md: 2,
                                                    sm: 2,
                                                    xs: 1,
                                                },
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 2,
                                                    mb: 3,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        height: '60px',
                                                        justifyContent:
                                                            'space-between',
                                                        borderBottom:
                                                            '1px solid lightGrey',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ color: 'grey' }}
                                                    >
                                                        Post Date
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {new Date(
                                                            data.date_posted
                                                        ).toLocaleDateString(
                                                            'en-US',
                                                            {
                                                                weekday: 'long',
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric',
                                                            }
                                                        )}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        height: '60px',
                                                        justifyContent:
                                                            'space-between',
                                                        borderBottom:
                                                            '1px solid lightGrey',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ color: 'grey' }}
                                                    >
                                                        Closing Date
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {new Date(
                                                            data.closing_date
                                                        ).toLocaleDateString(
                                                            'en-US',
                                                            {
                                                                weekday: 'long',
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric',
                                                            }
                                                        )}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        height: '60px',
                                                        justifyContent:
                                                            'space-between',
                                                        borderBottom:
                                                            '1px solid lightGrey',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ color: 'grey' }}
                                                    >
                                                        Number Of Vacancy
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {data.numberOfVacancy}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        height: '60px',
                                                        justifyContent:
                                                            'space-between',
                                                        borderBottom:
                                                            '1px solid lightGrey',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ color: 'grey' }}
                                                    >
                                                        Salary Range
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {data.salary}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        height: '60px',
                                                        justifyContent:
                                                            'space-between',
                                                        borderBottom:
                                                            '1px solid lightGrey',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ color: 'grey' }}
                                                    >
                                                        Year Of Experience
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {data.yearsOfExperience}{' '}
                                                        Years
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        height: '60px',
                                                        justifyContent:
                                                            'space-between',
                                                        borderBottom:
                                                            '1px solid lightGrey',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ color: 'grey' }}
                                                    >
                                                        Contract Type
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {data.contractType}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        height: '60px',
                                                        justifyContent:
                                                            'space-between',
                                                        borderBottom:
                                                            '1px solid lightGrey',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ color: 'grey' }}
                                                    >
                                                        Minimum Education
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {data.minimumEducation}{' '}
                                                        Degree
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Container>
                        </Paper>
                    </Box>
                </Container>
            </Box>
        );
    }
};

export default JobDetails;
