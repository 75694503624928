import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../utils/Colors';

const SuccessStoryCard = (props) => {
    const data = props.data;
    return (
        <Paper
            sx={{
                height: '450px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Box
                component={'img'}
                src={data.image}
                alt={
                    data.image &&
                    data.image.substring(data.image.lastIndexOf('/') + 1)
                }
                sx={{ width: '100%', height: '60%' }}
            />
            <Box m={1}>
                <Typography
                    fontWeight={'bold'}
                    sx={{
                        '&:hover': {
                            color: Colors.SECONDARY,
                        },
                    }}
                >
                    {data.title.length < 80
                        ? data.title
                        : `${data.title.slice(0, 80)}...`}
                </Typography>
            </Box>
            <Box m={1}>
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            data.description.length < 100
                                ? data.description
                                : `${data.description.slice(0, 100)}...`,
                    }}
                />
            </Box>
            <Box
                m={1}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Typography fontWeight={'bold'}>{data.date}</Typography>
                <Typography fontWeight={'bold'} sx={{ color: Colors.PRIMARY }}>
                    {data.service.name}
                </Typography>
            </Box>
        </Paper>
    );
};

export default SuccessStoryCard;
