import React from 'react';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DescriptionIcon from '@mui/icons-material/Description';
import { Link } from 'react-router-dom';

const JobCard = (props) => {
    const data = props.data;
    return (
        <Paper
            sx={{
                width: '100%',
                height: '100px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '10px',
                border: '1px solid lightGrey',
                '&:hover': {
                    transition: 'transform 0.1s ease',
                    transform: 'translateX(10px)',
                },
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, ml: 2 }}>
                <Box
                    component={'img'}
                    src={data.service.image}
                    alt={
                        data.service.image &&
                        data.service.image.substring(
                            data.service.image.lastIndexOf('/') + 1
                        )
                    }
                    sx={{ width: '50px', height: '50px', borderRadius: '50%' }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography fontWeight={'bold'}>
                        {data.title.length < 20
                            ? data.title
                            : `${data.title.slice(0, 40)}...`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Typography fontWeight={'bold'}>
                            {data.service.name}
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                            <LocationOnIcon fontSize="small" />
                            <Typography>Shahr-e Now</Typography>
                        </Box>
                        <Box>{data.date_posted}</Box>
                    </Box>
                </Box>
            </Box>
            <IconButton title="Show Job Details" sx={{ mr: 2 }}>
                <DescriptionIcon />
            </IconButton>
        </Paper>
    );
};

export default JobCard;
