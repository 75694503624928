import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const ServiceDetailsLoading = () => {
    return (
        <Box
            sx={{
                minHeight: '1000px', // Adjust height as needed
                backgroundColor: '#f0f0f0', // Adjust background color
            }}
        >
            <Container>
                <Grid container pt={8} spacing={2}>
                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minHeight: '500px', // Adjust height as needed
                                justifyContent: 'space-evenly',
                            }}
                        >
                            <Skeleton variant="text" width="90%" height={60} />
                            <Skeleton
                                variant="rectangular"
                                width="90%"
                                height={200}
                            />
                            <Skeleton variant="text" width="90%" height={40} />
                        </Box>
                    </Grid>
                    <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Skeleton
                                variant="rectangular"
                                width={400}
                                height={400}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box>
                    <Skeleton variant="text" width="100%" height={400} />
                </Box>
            </Container>
        </Box>
    );
};

export default ServiceDetailsLoading;
