import { Avatar, Box, Paper, Rating, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../utils/Colors';
import XIcon from '@mui/icons-material/X';

const TestimonialUserCard = (props) => {
    const data = props.data;
    return (
        <Paper
            sx={{
                width: '95%',
                height: '250px',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                pl: 2,
                gap: 2,
                mt: 4,
            }}
        >
            <Box sx={{ display: 'flex' }}>
                <Rating name="read-only" value={4.5} precision={0.5} readOnly />
                <Typography>(4.8/5)</Typography>
            </Box>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }}>
                    {data?.title}
                </Typography>
            </Box>
            <Box>
                <Typography>
                    {data?.description.length < 100
                        ? data?.description
                        : `${data?.description.slice(0, 100)}...`}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Avatar
                            src={data?.image}
                            alt={
                                data?.image &&
                                data?.image.substring(
                                    data?.image.lastIndexOf('/') + 1
                                )
                            }
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box>
                            <Typography>{data?.name}</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: Colors.PRIMARY }}>
                                @{data?.name}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box mr={2}>
                    <XIcon />
                </Box>
            </Box>
        </Paper>
    );
};

export default TestimonialUserCard;
