import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Colors from '../../utils/Colors';
import BlogCard from '../../components/BlogCard/BlogCard';
import { useQuery } from '@tanstack/react-query';
import { getObjects } from '../../api/Api';
import { Link } from 'react-router-dom';
import SkeletonLoading from '../../components/SkeletonLoading/SkeletonLoading';
import NotFoundData from '../../components/NoteFoundData/NotFoundData';
import SearchIcon from '@mui/icons-material/Search';
import { MetaTags } from 'react-meta-tags';

const Event = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const handleSearch = () => {
        refetch();
    };
    const { data, isError, isLoading, isSuccess, refetch } = useQuery(
        ['event'],
        () => getObjects('event', searchQuery)
    );
    if (isError) {
        return <Typography>Occurred an error</Typography>;
    }
    if (isLoading) {
        return <SkeletonLoading />;
    }
    if (isSuccess) {
        return (
            <Box>
                <MetaTags>
                    <title>Afghan cosmos - Event</title>
                    <meta name="description" content="Brows our Event" />
                    <meta
                        name="keywords"
                        content="afghan cosmos, afghan cosmos events, our events, event page, events "
                    />
                </MetaTags>
                <Box
                    sx={{
                        height: '400px',
                        backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            width: { xl: '50%', lg: '50%', xs: '80%' },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 3,
                        }}
                    >
                        <Typography
                            variant="h4"
                            fontWeight={'bold'}
                            sx={{
                                textAlign: 'center',
                                color: Colors.PRIMARY,
                                fontSize: { xl: '45px', xs: '30px' },
                            }}
                        >
                            Top Events
                        </Typography>
                        <Typography
                            fontWeight={'bold'}
                            sx={{ textAlign: 'center' }}
                        >
                            Find our latest events
                        </Typography>
                        <TextField
                            fullWidth
                            placeholder="Search Events"
                            variant="outlined"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <SearchIcon
                                        sx={{ marginLeft: 1, marginRight: 1 }}
                                    />
                                ),
                                endAdornment: (
                                    <Button
                                        variant="contained"
                                        onClick={handleSearch}
                                        sx={{
                                            borderRadius: '20px',
                                            width: '120px',
                                            fontSize: {
                                                xl: '15px',
                                                lg: '15px',
                                                md: '12px',
                                                sm: '11px',
                                                xs: '10px',
                                            },
                                        }}
                                    >
                                        Search
                                    </Button>
                                ),
                                sx: {
                                    borderRadius: '20px',
                                    backgroundColor: 'white',
                                },
                            }}
                        />
                    </Box>
                </Box>
                <Container sx={{ mt: 5 }}>
                    {data.length === 0 ? (
                        <NotFoundData type="event" />
                    ) : (
                        <Grid container spacing={2}>
                            {data.map((item, index) => (
                                <Grid
                                    item
                                    key={index}
                                    xl={4}
                                    lg={4}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                >
                                    <Link
                                        to={`/event/details/${item.id}/`}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <BlogCard data={item} />
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Container>
            </Box>
        );
    }
};

export default Event;
