import React, { useEffect, useState } from 'react';
import {
    Box,
    TextField,
    InputAdornment,
    IconButton,
    Typography,
    CircularProgress,
    Card,
    CardContent,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const fetchVerification = async (reg_no) => {
    const url = process.env.REACT_APP_API_URL;
    console.log(url);
    if (!reg_no) {
        throw new Error('No registration number provided.');
    }
    const response = await axios.get(
        `${url}verification/`, // Ensure the endpoint is correct
        {
            params: { reg_no }, // Ensure reg_no is correctly passed
        }
    );
    return response.data;
};

const Verification = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const { data, error, isLoading, refetch } = useQuery(
        ['verification', searchQuery],
        () => fetchVerification(searchQuery), // Correctly referencing searchQuery
        {
            enabled: false, // Disable automatic fetching
        }
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchQuery('');
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        if (searchQuery.trim()) {
            refetch(); // This will trigger the fetchVerification with the current searchQuery
        }
    };

    useEffect(() => {
        if (data) {
            navigate(`/verification/${searchQuery}`);
        }
    }, [data]);

    return (
        <Box
            sx={{
                padding: 4,
                maxWidth: '600px',
                margin: '0 auto',
                textAlign: 'center',
                fontFamily: 'Arial, sans-serif',
            }}
        >
            <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 'bold', color: '#333' }}
            >
                Search Verification
            </Typography>
            <form onSubmit={handleSearchSubmit}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search by Registration Number..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="action" />
                            </InputAdornment>
                        ),
                        endAdornment: searchQuery && (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClearSearch}
                                    edge="end"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                        },
                    }}
                />
            </form>

            {isLoading && <Box mt={3}></Box>}

            {error && (
                <Typography color="error" sx={{ mt: 3 }}>
                    {error.message}
                </Typography>
            )}

            {data && (
                <Card
                    sx={{
                        marginTop: 3,
                        padding: 2,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '10px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <CardContent>
                        {data.message || 'Verification data found.'}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default Verification;
