import { Box, Container, Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../../utils/Colors';

const BlogLoader = () => {
    return (
        <Box
            sx={{
                backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                minHeight: '1000px',
            }}
        >
            <Container>
                <Box>
                    <Typography
                        variant="h4"
                        fontWeight={'bold'}
                        pt={4}
                        mb={4}
                        sx={{ fontSize: { xs: '20px' } }}
                    >
                        <span
                            style={{
                                color: Colors.PRIMARY,
                                fontWeight: 1000,
                                paddingLeft: 5,
                                paddingRight: 5,
                            }}
                        >
                            loading..
                        </span>
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    {[...Array(6)].map((_, index) => (
                        <Grid
                            item
                            key={index}
                            xl={4}
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                        >
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={400}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default BlogLoader;
