import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Colors from '../../utils/Colors';

const TypeWriter = ({ text, delay }) => {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        let timeout;

        if (currentIndex < text.length) {
            timeout = setTimeout(() => {
                setCurrentText((prevText) => prevText + text[currentIndex]);
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }, delay);
        } else {
            // Reset to start typing from the beginning
            setCurrentText('');
            setCurrentIndex(0);
        }

        return () => clearTimeout(timeout);
    }, [currentIndex, delay, text]);

    return (
        <Typography
            sx={{
                fontWeight: 900,
                fontSize: {
                    xl: '50px',
                    lg: '50',
                    md: '40px',
                    sm: '35',
                    xs: '25px',
                },
                color: Colors.PRIMARY,
                textAlign: 'left',
            }}
        >
            {currentText}
        </Typography>
    );
};

export default TypeWriter;
