import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './router';
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';


const queryClient = new QueryClient({
    defaultOptions: {},
});

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <SnackbarProvider>
            <QueryClientProvider client={queryClient}>
                <Router />
            </QueryClientProvider>
        </SnackbarProvider>
    </React.StrictMode>
);
