import React, { useState } from 'react';
import {
    Box,
    Container,
    Grid,
    Typography,
    TextField,
    Button,
} from '@mui/material';
import Colors from '../../utils/Colors';
import BlogCard from '../../components/BlogCard/BlogCard';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getObjects } from '../../api/Api';
import BlogLoader from '../../components/SkeletonLoading/BlogLoading/BlogLoader';
import { MetaTags } from 'react-meta-tags';
import SearchIcon from '@mui/icons-material/Search';
import NotFoundData from '../../components/NoteFoundData/NotFoundData';

const Blog = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const handleSearch = () => {
        refetch();
    };
    const { data, isError, isLoading, isSuccess, refetch } = useQuery(
        ['blog'],
        () => getObjects('blog', searchQuery)
    );

    if (isError) {
        return <Typography>Occurred an error</Typography>;
    }

    if (isLoading) {
        return <BlogLoader />;
    }

    if (isSuccess) {
        return (
            <Box
                sx={{
                    backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                    minHeight: '1000px',
                }}
            >
                <MetaTags>
                    <title>Afghan cosmos - Blog</title>
                    <meta name="description" content="Browse our blog" />
                </MetaTags>
                <Container>
                    <Box>
                        <Typography
                            variant="h4"
                            fontWeight={'bold'}
                            pt={4}
                            mb={4}
                            sx={{ fontSize: { xs: '20px' } }}
                        >
                            Our Latest
                            <span
                                style={{
                                    color: Colors.PRIMARY,
                                    fontWeight: 1000,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                }}
                            >
                                Blogs
                            </span>
                        </Typography>
                        <Box mb={5}>
                            <TextField
                                fullWidth
                                placeholder="Search blog"
                                variant="outlined"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <SearchIcon
                                            sx={{
                                                marginLeft: 1,
                                                marginRight: 1,
                                            }}
                                        />
                                    ),
                                    endAdornment: (
                                        <Button
                                            variant="contained"
                                            onClick={handleSearch}
                                            sx={{
                                                borderRadius: '20px',
                                                width: '120px',
                                                fontSize: {
                                                    xl: '15px',
                                                    lg: '15px',
                                                    md: '12px',
                                                    sm: '11px',
                                                    xs: '10px',
                                                },
                                            }}
                                        >
                                            Search
                                        </Button>
                                    ),
                                    sx: {
                                        borderRadius: '20px',
                                        backgroundColor: 'white',
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                    {data.length === 0 ? (
                        <NotFoundData type="blog" />
                    ) : (
                        <Grid container spacing={2}>
                            {data.map((item, index) => (
                                <Grid
                                    item
                                    key={index}
                                    xl={4}
                                    lg={4}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                >
                                    <Link
                                        to={`/blog/details/${item.id}/`}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <BlogCard data={item} />
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Container>
            </Box>
        );
    }
};

export default Blog;
