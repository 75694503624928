import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import Colors from '../../utils/Colors';
import { useQuery } from '@tanstack/react-query';
import { getObjects } from '../../api/Api';
import SkeletonLoading from '../../components/SkeletonLoading/SkeletonLoading';
import { Link } from 'react-router-dom';
import SuccessStoryCard from '../../components/SuccessStoryCard/SuccessStoryCard';
import { MetaTags } from 'react-meta-tags';
import NotFoundData from '../../components/NoteFoundData/NotFoundData';

const SuccessStory = () => {
    const imageUrl = `${process.env.REACT_APP_MEDIA_URL}images/png/afghancosmos-Trenner-gree.png`;
    const { data, isError, isLoading, isSuccess, refetch } = useQuery(
        ['success-story'],
        () => getObjects('success-story')
    );
    if (isError) {
        <Typography>Occurred an error</Typography>;
    }
    if (isLoading) {
        return <SkeletonLoading />;
    }
    if (isSuccess) {
        return (
            <Box>
                <MetaTags>
                    <title>Afghan cosmos - Success Story</title>
                    <meta
                        name="description"
                        content="Brows our Success story page"
                    />
                </MetaTags>
                {data.length === 0 ? (
                    <NotFoundData type="success story" />
                ) : (
                    <>
                        <Box
                            sx={{
                                height: '400px',
                                backgroundColor:
                                    Colors.SECTION_BACKGROUND_COLOR,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    width: { xl: '50%', lg: '50%', xs: '80%' },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 3,
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    fontWeight={'bold'}
                                    sx={{
                                        textAlign: 'center',
                                        color: Colors.PRIMARY,
                                        fontSize: { xl: '45px', xs: '30px' },
                                    }}
                                >
                                    success stories
                                </Typography>
                            </Box>
                        </Box>
                        <Container>
                            <Grid container>
                                <Grid
                                    item
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Shadows Into Light',
                                            fontStyle: 'normal',
                                            mt: 4,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        A Success Story from
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            color: Colors.PRIMARY,
                                            fontWeight: 'bold',
                                            mt: 2,
                                        }}
                                    >
                                        {data[0]?.title}
                                    </Typography>
                                    <Box component={'img'} src={imageUrl} />
                                </Grid>
                                <Grid
                                    item
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: {
                                                xl: 'row',
                                                lg: 'row',
                                                md: 'row',
                                                sm: 'column',
                                                xs: 'column',
                                            },
                                            boxSizing: 'border-box',
                                        }}
                                    >
                                        <Box>
                                            <Box
                                                sx={{
                                                    width: {
                                                        xl: '70%',
                                                        lg: '70%',
                                                        md: '80%',
                                                        sm: '90%',
                                                        xs: '90%',
                                                    },
                                                }}
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: data[0]
                                                            ?.description,
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box
                                                component={'img'}
                                                src={data[0]?.image}
                                                sx={{
                                                    width: {
                                                        xl: '500px',
                                                        lg: '500px',
                                                        md: '400px',
                                                        sm: '500px',
                                                        xs: '100%',
                                                    },
                                                    height: {
                                                        xl: '400px',
                                                        lg: '400px',
                                                        md: '250px',
                                                        sm: '300px',
                                                        xs: '250px',
                                                    },
                                                    borderRadius: '10px',
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                        <Box
                            sx={{
                                borderTop: '1px solid lightGrey',
                                mt: 2,
                                mb: 4,
                            }}
                        />
                        <Container>
                            <Grid container spacing={2}>
                                {data
                                    .slice(1, data.length)
                                    .map((item, index) => (
                                        <Grid
                                            item
                                            key={index}
                                            xl={4}
                                            lg={4}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                        >
                                            <Link
                                                to={`/success-story/details/${item.id}/`}
                                                style={{
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                <SuccessStoryCard data={item} />
                                            </Link>
                                        </Grid>
                                    ))}
                            </Grid>
                        </Container>
                    </>
                )}
            </Box>
        );
    }
};

export default SuccessStory;
