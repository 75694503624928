import React from 'react';
import {
    AppBar,
    Box,
    Button,
    Container,
    Toolbar,
    Typography,
} from '@mui/material/';
import Colors from '../../../utils/Colors';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext';

const TopBar = () => {
    const { value, setValue } = useAppContext();
    return (
        <AppBar
            position="sticky"
            sx={{ backgroundColor: Colors.PRIMARY, width: '100%' }}
        >
            <Container>
                <Toolbar
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Link to={'/'} style={{ textDecoration: 'none' }}>
                        <Button onClick={() => setValue('home')}>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: {
                                        xl: '25px',
                                        lg: '25px',
                                        md: '15px',
                                        sm: '15px',
                                        xs: '15px',
                                    },
                                    color: Colors.WHITE,
                                }}
                            >
                                AFGHAN COSMOS
                            </Typography>
                        </Button>
                    </Link>
                    <Box sx={{ display: 'fex', alignItems: 'center', gap: 1 }}>
                        <MyLocationIcon
                            sx={{
                                fontSize: {
                                    xl: '20px',
                                    lg: '20px',
                                    md: '18px',
                                    sm: '15px',
                                    xs: '15px',
                                },
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: {
                                    xl: '20px',
                                    lg: '20px',
                                    md: '18px',
                                    sm: '15px',
                                    xs: '15px',
                                },
                            }}
                        >
                            Qala-e-Fatullah
                        </Typography>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default TopBar;
