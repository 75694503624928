import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { getObjects } from '../../../api/Api';
import { useQuery } from '@tanstack/react-query';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Colors from '../../../utils/Colors';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useAppContext } from '../../../context/AppContext';

const OurPartners = () => {
    const { value, setValue } = useAppContext();
    const [slidesToShow, setSlidesToShow] = useState(8); // Default value

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            let slides;
            if (screenWidth >= 1200) {
                slides = 8;
            } else if (screenWidth >= 992) {
                slides = 6;
            } else if (screenWidth >= 768) {
                slides = 5;
            } else if (screenWidth >= 576) {
                slides = 4;
            } else {
                slides = 2;
            }
            setSlidesToShow(slides);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { data, isLoading, isError, isSuccess } = useQuery(
        ['our-partner'],
        () => {
            return getObjects('our-partner');
        }
    );

    if (isError) {
        return <Typography>Occurred an error</Typography>;
    }

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (isSuccess) {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: Math.min(slidesToShow, data.length), // Adjust slidesToShow dynamically
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
        };

        return (
            <Box mt={4}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 5,
                        mb: 5,
                    }}
                >
                    <Box
                        sx={{
                            width: '200px',
                            height: '30px',
                            borderRadius: '20px',
                            backgroundColor: Colors.PRIMARY_LIGHT,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                color: Colors.WHITE,
                                fontSize: {
                                    xl: '25px',
                                    lg: '25px',
                                    md: '20px',
                                    sm: '18px',
                                    xs: '15px',
                                },
                            }}
                        >
                            Our Partners
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: {
                            xl: '95%',
                            lg: '95%',
                            md: '95%',
                            sm: '92%',
                            xs: '90%',
                        },
                    }}
                >
                    <Slider {...settings}>
                        {data.map((partner) => (
                            <Box key={partner.id}>
                                <a href={partner.link}>
                                    <Box
                                        component={'img'}
                                        src={partner.logo}
                                        alt={
                                            partner.logo &&
                                            partner.logo.substring(
                                                partner.logo.lastIndexOf('/') +
                                                    1
                                            )
                                        }
                                        sx={{
                                            width: '100px',
                                            margin: '0 auto',
                                        }}
                                    />
                                </a>
                            </Box>
                        ))}
                    </Slider>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to={'/contact/'} style={{ textDecoration: 'none' }}>
                        <Button
                            onClick={() => setValue('contact')}
                            sx={{
                                backgroundColor: Colors.PRIMARY,
                                color: Colors.WHITE,
                                width: '180px',
                                height: '60px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '15px',
                                gap: 2,
                                mt: 5,
                                mb: 5,
                            }}
                        >
                            <Typography
                                sx={{
                                    borderRight: '0.2px solid white',
                                    pr: 1,
                                    fontSize: '12px',
                                }}
                            >
                                Contact Us
                            </Typography>
                            <ArrowForwardIcon />
                        </Button>
                    </Link>
                </Box>
            </Box>
        );
    }
};

export default OurPartners;
