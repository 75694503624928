import React, { useEffect } from 'react';
import Welcome from './welcome/Welcome';
import { Box } from '@mui/material';
import Services from './services/Services';
import Scholarship from './scholarship/Scholarship';
import OurTeam from './OurTeam/OurTeam';
import JoinUs from './JoinUs/JoinUs';
import Testimonial from './Testimonial/Testimonial';
import Article from './Article/Article';
import OurPartners from './our-partners/OurPartners';
import { MetaTags } from 'react-meta-tags';
import axios from 'axios';

const Home = () => {
    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_API_URL}increment-viewer-count/`)
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.error('Error updating viewer count:', error);
            });
    }, []);
    return (
        <Box>
            <MetaTags>
                <title>Afghan cosmos- Home</title>
                <meta name="description" content="Brows our Home page" />
                <meta
                    name="keywords"
                    content="afghan cosmos, afghan cosmos home page, home, home page, صفحه اصلی"
                />
            </MetaTags>
            <Welcome />
            <Services />
            <Scholarship />
            <OurTeam />
            <Article />
            <Testimonial />
            <OurPartners />
            <JoinUs />
        </Box>
    );
};

export default Home;
