import { Box, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../utils/Colors';

const OurTeamCard = (props) => {
    const data = props.data;
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mb: { xs: 2 },
            }}
        >
            <Box
                sx={{
                    border: '1px solid',
                    borderColor: Colors.PRIMARY,
                    borderRadius: '50%',
                    width: {
                        xl: '150px',
                        lg: '150px',
                        md: '150px',
                        sm: '140px',
                        xs: '130px',
                    },
                    height: {
                        xl: '150px',
                        lg: '150px',
                        md: '150px',
                        sm: '140px',
                        xs: '130px',
                    },
                }}
            >
                <Box
                    component={'img'}
                    src={data.image}
                    alt={
                        data.image &&
                        data.image.substring(data.image.lastIndexOf('/') + 1)
                    }
                    sx={{
                        width: {
                            xl: '150px',
                            lg: '150px',
                            md: '150px',
                            sm: '140px',
                            xs: '130px',
                        },
                        height: {
                            xl: '150px',
                            lg: '150px',
                            md: '150px',
                            sm: '140px',
                            xs: '130px',
                        },
                        borderRadius: '50%',
                    }}
                />
            </Box>
            <Typography
                mt={2}
                fontWeight="bold"
                sx={{
                    fontSize: {
                        xl: '18px',
                        lg: '18px',
                        md: '12px',
                        sm: '10px',
                        xs: '10px',
                    },
                    textAlign: 'center',
                }}
            >
                {data.name}
            </Typography>
            <Typography
                sx={{
                    textAlign: 'center',
                    fontSize: {
                        xl: '15px',
                        lg: '15px',
                        md: '12px',
                        sm: '10px',
                        xs: '10px',
                    },
                }}
            >
                {data.position}
            </Typography>
        </Box>
    );
};

export default OurTeamCard;
