import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import Colors from '../../utils/Colors';

const ArticleCard = (props) => {
    const data = props.data;
    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: '110px',
                gap: 1,
                mt: { xl: 0, lg: 0, md: 0, sm: 2, xs: 2 },
                ml: { xl: 2, lg: 2, md: 2, sm: 0, xs: 0 },
            }}
        >
            <img
                src={data.images[0].image}
                alt={
                    data.images[0].image &&
                    data.images[0].image.substring(
                        data.images[0].image.lastIndexOf('/') + 1
                    )
                }
                style={{
                    width: '150px',
                    height: '110px',
                    borderRadius: '5px',
                    objectFit: 'cover',
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: {
                            xl: '14px',
                            lg: '14px',
                            md: '12px',
                            sm: '8px',
                            xs: '10px',
                        },
                    }}
                >
                    {data.title}
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Typography
                        sx={{
                            color: Colors.PRIMARY,
                            borderRight: '1px solid lightGrey',
                            pr: 1,
                            fontSize: {
                                xl: '12px',
                                lg: '12px',
                                md: '10px',
                                sm: '8px',
                                xs: '10px',
                            },
                        }}
                    >
                        {data.service.name}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: {
                                xl: '14px',
                                lg: '14px',
                                md: '12px',
                                sm: '8px',
                                xs: '10px',
                            },
                        }}
                    >
                        {data.date}
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
};

export default ArticleCard;
