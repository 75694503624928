import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Colors from '../../../utils/Colors';
import ServiceDetailsServicesSection from './serviceDetailsServicesSection/ServiceDetailsServicesSection';
import ServiceDetailsTeamSection from './serviceDetailsTeamSection/ServiceDetailsTeamSection';
import ServiceDetailsProjectSection from './serviceDetailsProjectSection/ServiceDetailsProjectSection';
import { useQuery } from '@tanstack/react-query';
import { getObject } from '../../../api/Api';
import ServiceDetailsLoading from '../../../components/SkeletonLoading/ServiceDetailsLoading';
import ServiceDetailsTestimonialSection from './serviceDetailsTestimonialSection/ServiceDetailsTestimonialSection';
import { MetaTags } from 'react-meta-tags';

const ServiceDetails = () => {
    const { id } = useParams();

    const { data, isLoading, isError, isSuccess, refetch } = useQuery(
        ['service-detail', id],
        () => getObject('service', id),
        {
            enabled: false,
        }
    );

    useEffect(() => {
        refetch();
    }, [id, refetch]);

    if (isLoading) {
        return <ServiceDetailsLoading />;
    }

    if (isError) {
        return <Typography>Error</Typography>;
    }

    if (isSuccess) {
        return (
            <Box
                sx={{
                    minHeight: '1000px',
                    backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                }}
            >
                <MetaTags>
                    <title>Afghan cosmos - {data.name}</title>
                    <meta
                        name="description"
                        content="Brows our service details "
                    />
                </MetaTags>
                <Container>
                    <Grid container pt={8} spacing={2}>
                        <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: '500px',
                                    justifyContent: 'space-evenly',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: {
                                            xl: '90%',
                                            lg: '90%',
                                            md: '90%',
                                            sm: '90%',
                                            xs: '100%',
                                        },
                                        mb: { xl: 0, lg: 0, md: 1, xs: 5 },
                                    }}
                                >
                                    <Typography
                                        variant="h2"
                                        fontWeight={'bold'}
                                        sx={{
                                            fontSize: {
                                                xl: '50px',
                                                lg: '50px',
                                                md: '45px',
                                                sm: '40px',
                                                xs: '28px',
                                            },
                                        }}
                                    >
                                        {data.slogan}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-evenly',
                                        minHeight: '200px',
                                    }}
                                >
                                    <Typography>{data.about}</Typography>
                                    <Link
                                        to={'/contact/'}
                                        style={{ marginTop: '20px' }}
                                    >
                                        <Button variant="contained">
                                            Contact us
                                        </Button>
                                    </Link>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    component={'img'}
                                    src={data.image}
                                    sx={{
                                        width: {
                                            xl: '400px',
                                            lg: '400px',
                                            md: '350px',
                                            sm: '400px',
                                            xs: '100%',
                                        },
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box>
                        <ServiceDetailsServicesSection data={data.services} />
                        {/* <ServiceDetailsTeamSection data={data.team} /> */}
                        <ServiceDetailsProjectSection data={data.project} />
                        <ServiceDetailsTestimonialSection
                            data={data.testimonial}
                        />
                    </Box>
                </Container>
            </Box>
        );
    }
};

export default ServiceDetails;
