import React, { createContext, useContext, useState } from 'react';
import TopBar from './TopBar/TopBar';
import { Outlet } from 'react-router-dom';
import Footer from './footer/Footer';
import NavBar from './NavBar/NavBar';
import { AppProvider } from '../../context/AppContext';
import { MetaTags } from 'react-meta-tags';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

const Layout = () => {
    const theme = createTheme({
        typography: {
            fontFamily: ['"Inter", "Segoe UI", "sans-serif"'].join(','),
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <AppProvider>
                <MetaTags>
                    <meta
                        name="description"
                        content="afghan cosmos layout section"
                    />
                </MetaTags>
                <CssBaseline />
                <TopBar />
                <NavBar />
                <Outlet />
                <Footer />
            </AppProvider>
        </ThemeProvider>
    );
};

export default Layout;
