import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    const imageUrl = `${process.env.REACT_APP_MEDIA_URL}images/png/error.png`;
    return (
        <Box
            sx={{
                height: '800px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
            }}
        >
            <MetaTags>
                <title>Afghan cosmos - Not Found Page</title>
                <meta
                    name="description"
                    content="afghan cosmos not found page section"
                />
            </MetaTags>
            <Box
                component={'img'}
                src={imageUrl}
                sx={{
                    width: {
                        xl: '300px',
                        lg: '300px',
                        md: '250px',
                        sm: '200px',
                        xs: '200px',
                    },
                }}
            />

            <Typography variant="h4" fontWeight={'bold'} textAlign={'center'}>
                404: The page you are looking for isn't here
            </Typography>

            <Typography textAlign={'center'}>
                You either tried some shady route or you came here by mistake.
                Whichever it is, try using the navigation
            </Typography>

            <Link to={'/'}>
                <Button variant="contained">Go back to home</Button>
            </Link>
        </Box>
    );
};

export default NotFoundPage;
