import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../utils/Colors';
import { MetaTags } from 'react-meta-tags';

const BlogCard = (props) => {
    const data = props.data;
    const imageUrl = `${process.env.REACT_APP_MEDIA_URL}images/logo/Logo.png`;
    // Function to encode special characters in HTML
    const encodeHtml = (html) => {
        return html
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
    };

    // Convert data object to JSON string for embedding in JSX
    const jsonData = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        headline: data.title,
        description: data.description,
        image: data.images[0].image,
        datePublished: data.date,
        publisher: {
            '@type': 'Afghan Cosmos',
            name: data.title,
            logo: {
                '@type': 'ImageObject',
                url: imageUrl,
            },
        },
    });

    return (
        <Paper
            sx={{
                height: '450px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <MetaTags>
                <meta property="og:image" content={data.images[0].image} />
                <meta name="twitter:image" content={data.images[0].image} />
            </MetaTags>
            <Box
                component={'img'}
                src={data.images[0].image}
                alt={
                    data?.images[0].image &&
                    data?.images[0].image.substring(
                        data?.images[0].image.lastIndexOf('/') + 1
                    )
                }
                sx={{ width: '100%', height: '60%', objectFit: 'cover' }}
            />
            <Box m={1}>
                <Typography
                    fontWeight={'bold'}
                    sx={{
                        '&:hover': {
                            color: Colors.SECONDARY,
                        },
                    }}
                >
                    {data.title.length < 80
                        ? data.title
                        : `${data.title.slice(0, 80)}...`}
                </Typography>
            </Box>
            <Box m={1}>
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            data.description.length < 100
                                ? data.description
                                : `${data.description.slice(0, 100)}...`,
                    }}
                />
            </Box>
            <Box
                m={1}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Typography fontWeight={'bold'}>{data.date}</Typography>
                <Typography fontWeight={'bold'} sx={{ color: Colors.PRIMARY }}>
                    {data.service.name}
                </Typography>
            </Box>
            <script type="application/ld+json">{jsonData}</script>
        </Paper>
    );
};

export default BlogCard;
