import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../utils/Colors';
import { MetaTags } from 'react-meta-tags';

const About = () => {
    const imageUrl = `${process.env.REACT_APP_MEDIA_URL}images/jpeg/consulting.jpeg`;
    return (
        <Box
            sx={{
                minHeight: '900px',
            }}
        >
            <MetaTags>
                <title>Afghan cosmos - About</title>
                <meta name="description" content="Brows our About" />
            </MetaTags>
            <Container>
                <Box mb={4}>
                    <Typography
                        pt={10}
                        variant="h4"
                        fontWeight={'bold'}
                        sx={{ color: Colors.PRIMARY }}
                    >
                        About us
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        sx={{ order: { xl: 1, lg: 1, md: 1, sm: 1, xs: 2 } }}
                    >
                        <Paper
                            sx={{
                                width: '100%',
                                minHeight: '600px',
                                borderRadius: '10px',
                            }}
                        >
                            <Container>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: 'column',
                                        minHeight: '600px',
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            fontWeight={'bold'}
                                            variant="h3"
                                            sx={{
                                                textAlign: {
                                                    xl: 'left',
                                                    lg: 'left',
                                                    md: 'left',
                                                    sm: 'left',
                                                    xs: 'center',
                                                },
                                                fontSize: {
                                                    xl: '50px',
                                                    lg: '50px',
                                                    md: '45px',
                                                    sm: '40px',
                                                    xs: '40px',
                                                },
                                                mt: {
                                                    xs: 2,
                                                },
                                            }}
                                        >
                                            Our
                                            <span
                                                style={{
                                                    color: Colors.PRIMARY,
                                                    fontWeight: 1000,
                                                    paddingLeft: 6,
                                                    paddingRight: 6,
                                                }}
                                            >
                                                Dream
                                            </span>
                                            is Global Learning
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mt: { xs: 4 } }}>
                                        <Typography>
                                            Established in June 2022, Afghan
                                            Cosmos Group is a creative team of
                                            diverse talent with a mission of a
                                            profound improvement and efficiency
                                            in the way businesses are run in the
                                            country. ACG provides a vast range
                                            of capacity building trainings in
                                            Business Management, Marketing,
                                            Finance and Tax Management,
                                            Nutrition and Wash and Human
                                            Resource Management. ACG is a
                                            professional and well-known name in
                                            education services including
                                            scholarship services, student visa
                                            assistance and education consulting.
                                            We, at ACG, target steady growth
                                            with stability so as to develop our
                                            organization to the level of an
                                            institution in the years to come.
                                            ACG’s personnel has extensive
                                            experience of providing consulting
                                            services and working with national
                                            and international organizations/
                                            Businesses including UN and other
                                            local and interanational
                                            institutions.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Container>
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        sx={{ order: { xl: 2, lg: 2, md: 2, sm: 2, xs: 1 } }}
                    >
                        <Box>
                            <Box
                                component={'img'}
                                src={imageUrl}
                                alt={
                                    imageUrl &&
                                    imageUrl.substring(
                                        imageUrl.lastIndexOf('/') + 1
                                    )
                                }
                                sx={{ width: '100%', borderRadius: '20px' }}
                            />
                        </Box>
                        <Paper
                            sx={{ width: '100%', borderRadius: '10px', mt: 4 }}
                        >
                            <Container>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 2,
                                        p: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '250px',
                                            height: '100px',
                                            backgroundColor:
                                                Colors.SECTION_BACKGROUND_COLOR,
                                            borderRadius: '20px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'left',
                                            justifyContent: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        <Typography
                                            fontWeight={'bold'}
                                            variant="h5"
                                            ml={2}
                                        >
                                            300 +
                                        </Typography>
                                        <Typography
                                            ml={2}
                                            sx={{
                                                fontSize: {
                                                    sm: '15px',
                                                    xs: '12px',
                                                },
                                            }}
                                        >
                                            Scholarships
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '250px',
                                            height: '100px',
                                            backgroundColor:
                                                Colors.SECTION_BACKGROUND_COLOR,
                                            borderRadius: '20px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'left',
                                            justifyContent: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        <Typography
                                            fontWeight={'bold'}
                                            variant="h5"
                                            ml={2}
                                        >
                                            50 +
                                        </Typography>
                                        <Typography
                                            ml={2}
                                            sx={{
                                                fontSize: {
                                                    sm: '15px',
                                                    xs: '12px',
                                                },
                                            }}
                                        >
                                            Trainings
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 2,
                                        p: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '250px',
                                            height: '100px',
                                            backgroundColor:
                                                Colors.SECTION_BACKGROUND_COLOR,
                                            borderRadius: '20px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'left',
                                            justifyContent: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        <Typography
                                            fontWeight={'bold'}
                                            variant="h5"
                                            ml={2}
                                        >
                                            10 +
                                        </Typography>
                                        <Typography
                                            ml={2}
                                            sx={{
                                                fontSize: {
                                                    sm: '15px',
                                                    xs: '12px',
                                                },
                                            }}
                                        >
                                            Service
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '250px',
                                            height: '100px',
                                            backgroundColor:
                                                Colors.SECTION_BACKGROUND_COLOR,
                                            borderRadius: '20px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'left',
                                            justifyContent: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        <Typography
                                            fontWeight={'bold'}
                                            variant="h5"
                                            ml={2}
                                        >
                                            10 +
                                        </Typography>
                                        <Typography
                                            ml={2}
                                            sx={{
                                                fontSize: {
                                                    sm: '15px',
                                                    xs: '12px',
                                                },
                                            }}
                                        >
                                            International Partners
                                        </Typography>
                                    </Box>
                                </Box>
                            </Container>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default About;
