import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../../utils/Colors';
import TypeWriter from '../../../components/TypeWriter/TypeWriter';

const Welcome = () => {
    const imageUrl = `${process.env.REACT_APP_MEDIA_URL}images/logo/logo.png`;
    return (
        <Container>
            <Box sx={{ mt: { xl: 20, lg: 20, md: 15, sm: 10, xs: 5 } }}>
                <Grid container>
                    <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    borderRadius: '15px',
                                    backgroundColor:
                                        Colors.PRIMARY_BACKGROUND_LIGHT,
                                    width: {
                                        xl: '400px',
                                        lg: '400px',
                                        md: '300px',
                                        sm: '300px',
                                        xs: '230px',
                                    },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '40px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: Colors.PRIMARY,
                                        fontWeight: 'bold',
                                        fontSize: {
                                            xl: '25px',
                                            lg: '25px',
                                            md: '20px',
                                            sm: '18px',
                                            xs: '15px',
                                        },
                                    }}
                                >
                                    Welcome To Afghan Cosmos
                                </Typography>
                            </Box>
                            <Box sx={{ height: '100px' }}>
                                <Typography
                                    sx={{
                                        fontWeight: 900,
                                        fontSize: {
                                            xl: '50px',
                                            lg: '50',
                                            md: '40px',
                                            sm: '35',
                                            xs: '25px',
                                        },
                                        textAlign: 'left',
                                    }}
                                >
                                    Your Trusted Partner in
                                    <TypeWriter
                                        text="Growth and Development.  "
                                        delay={200}
                                    />
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    pt: {
                                        xl: 12,
                                        lg: 12,
                                        md: 10,
                                        sm: 8,
                                        xs: 5,
                                    },
                                    width: {
                                        xl: '80%',
                                        lg: '80%',
                                        md: '80%',
                                        sm: '100%',
                                        xs: '100%',
                                    },
                                }}
                            >
                                <Typography>
                                    Welcome to Afghan Cosmos, where we are
                                    dedicated to facilitating access to the
                                    modern and digital world. Through
                                    scholarships, capacity building, management
                                    consulting, and IT services, we empower
                                    young talents and collaborate strategically
                                    with national and international institutions
                                    to provide innovative solutions for the
                                    challenges of the digital age.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <img
                                src={imageUrl}
                                alt={
                                    imageUrl &&
                                    imageUrl.substring(
                                        imageUrl.lastIndexOf('/') + 1
                                    )
                                }
                                style={{ width: '300px', objectFit: 'cover' }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    height: '200px',
                    backgroundColor: Colors.PRIMARY,
                    mt: 3,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: Colors.WHITE,
                                    fontWeight: 'bold',
                                    fontSize: '40px',
                                    fontSize: {
                                        xl: '25px',
                                        lg: '25px',
                                        md: '20px',
                                        sm: '15px',
                                        xs: '12px',
                                    },
                                }}
                            >
                                300 +
                            </Typography>
                            <Typography
                                sx={{
                                    color: Colors.WHITE,
                                    fontWeight: 'bold',
                                    fontSize: {
                                        xl: '15px',
                                        lg: '15px',
                                        md: '15px',
                                        sm: '12px',
                                        xs: '10px',
                                    },
                                }}
                            >
                                Scholarships
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: Colors.WHITE,
                                    fontWeight: 'bold',
                                    fontSize: '40px',
                                    fontSize: {
                                        xl: '25px',
                                        lg: '25px',
                                        md: '20px',
                                        sm: '15px',
                                        xs: '12px',
                                    },
                                    textAlign: 'center',
                                }}
                            >
                                50 +
                            </Typography>
                            <Typography
                                sx={{
                                    color: Colors.WHITE,
                                    fontWeight: 'bold',
                                    fontSize: {
                                        xl: '15px',
                                        lg: '15px',
                                        md: '15px',
                                        sm: '12px',
                                        xs: '10px',
                                    },
                                    textAlign: 'center',
                                }}
                            >
                                Trainings
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: Colors.WHITE,
                                    fontWeight: 'bold',
                                    fontSize: '40px',
                                    fontSize: {
                                        xl: '25px',
                                        lg: '25px',
                                        md: '20px',
                                        sm: '15px',
                                        xs: '12px',
                                    },
                                    textAlign: 'center',
                                }}
                            >
                                10 +
                            </Typography>
                            <Typography
                                sx={{
                                    color: Colors.WHITE,
                                    fontWeight: 'bold',
                                    fontSize: {
                                        xl: '17px',
                                        lg: '17px',
                                        md: '15px',
                                        sm: '12px',
                                        xs: '10px',
                                    },
                                    textAlign: 'center',
                                }}
                            >
                                Services
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: Colors.WHITE,
                                    fontWeight: 'bold',
                                    fontSize: '40px',
                                    fontSize: {
                                        xl: '25px',
                                        lg: '25px',
                                        md: '20px',
                                        sm: '15px',
                                        xs: '12px',
                                    },
                                    textAlign: 'center',
                                }}
                            >
                                10 +
                            </Typography>
                            <Typography
                                sx={{
                                    color: Colors.WHITE,
                                    fontWeight: 'bold',
                                    fontSize: {
                                        xl: '15px',
                                        lg: '15px',
                                        md: '15px',
                                        sm: '12px',
                                        xs: '10px',
                                    },
                                    textAlign: 'center',
                                }}
                            >
                                International partners
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default Welcome;
