import { Box, Container, Grid, Rating, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../../../utils/Colors';
import TestimonialUserCard from '../../../../components/TestimonialUserCard/TestimonialUserCard';

const ServiceDetailsTestimonialSection = (props) => {
    const imageUrl = `${process.env.REACT_APP_MEDIA_URL}images/worldMap/world-map.png`;
    const data = props.data;
    return (
        <Box
            sx={{
                minHeight: '700px',
                borderTop: '0.5px solid lightGrey',
                backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                backgroundImage: `url(${imageUrl})`,
                backgroundPosition: 'top center',
                backgroundRepeat: 'no-repeat',
                mt: 8,
            }}
        >
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 3,
                        pt: 5,
                    }}
                >
                    <Box
                        sx={{
                            width: '200px',
                            height: '30px',
                            borderRadius: '20px',
                            backgroundColor: Colors.PRIMARY_LIGHT,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                color: Colors.WHITE,
                                fontSize: {
                                    xl: '30px',
                                    lg: '30px',
                                    md: '25px',
                                    sm: '20px',
                                    xs: '15px',
                                },
                            }}
                        >
                            Testimonial
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: {
                                        xl: '30px',
                                        lg: '30px',
                                        md: '25px',
                                        sm: '20px',
                                        xs: '16px',
                                    },
                                    textAlign: 'center',
                                }}
                            >
                                See What Our Respected
                                <span
                                    style={{
                                        color: Colors.PRIMARY,
                                        fontWeight: 1000,
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                    }}
                                >
                                    Clients
                                </span>
                                Say About Us
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    width: { xl: '700px' },
                                    textAlign: 'center',
                                    fontSize: {
                                        xl: '25px',
                                        lg: '25px',
                                        md: '20px',
                                        sm: '16px',
                                        xs: '12px',
                                    },
                                }}
                            >
                                We welcome valuable feedbacks of our Clients
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Rating
                                name="read-only"
                                value={4.5}
                                precision={0.5}
                                readOnly
                            />
                            <Typography>(4.8/5)</Typography>
                        </Box>
                        <Grid container spacing={2}>
                            {data.map((item, index) => (
                                <Grid
                                    key={index}
                                    item
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                >
                                    <TestimonialUserCard data={item} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default ServiceDetailsTestimonialSection;
