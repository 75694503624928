import React from 'react';
import { Box, CardMedia, useMediaQuery } from '@mui/material';

const VideoPlayer = (props) => {
    const data = props.data;
    const matches = useMediaQuery('(max-width:600px)');
    const playerHeight = matches ? '300px' : '500px';
    return (
        <Box sx={{ maxWidth: '100%', mt: 2 }}>
            <CardMedia
                component="video"
                controls
                src={data}
                type="video/mp4"
                sx={{
                    width: '100%',
                    height: playerHeight,
                    borderRadius: '10px',
                }}
            />
        </Box>
    );
};

export default VideoPlayer;
