import React, { useState } from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    MenuItem,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import Colors from '../../utils/Colors';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { addObject } from '../../api/Api';
import { enqueueSnackbar } from 'notistack';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { MetaTags } from 'react-meta-tags';

const howHearChoice = [
    { id: 1, value: 'LinkedIn' },
    { id: 2, value: 'Friend' },
    { id: 3, value: 'OurWebsite' },
    { id: 4, value: 'Other' },
];

const JobApply = () => {
    const { id } = useParams();
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [streetAddress2, setStreetAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [linkedIn, setLinkedIn] = useState('');
    const [howHear, setHowHear] = useState('');
    const [howHearSpecify, setHowHearSpecify] = useState('');
    const [coverLater, setCoverLater] = useState('');
    const [cv, setCV] = useState('');
    // error section
    const [firstNameError, setFirstNameError] = useState();
    const [lastNameError, setLastNameError] = useState();
    const [streetAddressError, setStreetAddressError] = useState();
    const [cityError, setCityError] = useState();
    const [stateError, setStateError] = useState();
    const [emailError, setEmailError] = useState();
    const [phoneNumberError, setPhoneNumberError] = useState();
    const [howHearError, setHowHearError] = useState();
    const [coverLaterError, setCoverLaterError] = useState();
    const [cvError, setCVError] = useState();

    const handleCoverLaterChange = (value) => {
        setCoverLater(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let isValid = true;

        // Validation for First Name
        if (!firstName || firstName.trim() === '') {
            setFirstNameError('First Name cannot be empty');
            isValid = false;
        } else {
            setFirstNameError();
        }

        // Validation for Last Name
        if (!lastName || lastName.trim() === '') {
            setLastNameError('Last Name cannot be empty');
            isValid = false;
        } else {
            setLastNameError();
        }

        // Validation for Street Address
        if (!streetAddress || streetAddress.trim() === '') {
            setStreetAddressError('Street Address cannot be empty');
            isValid = false;
        } else {
            setStreetAddressError();
        }

        // Validation for city
        if (!city || city.trim() === '') {
            setCityError('city cannot be empty');
            isValid = false;
        } else {
            setCityError();
        }

        // Validation for State
        if (!state || state.trim() === '') {
            setStateError('State cannot be empty');
            isValid = false;
        } else {
            setStateError();
        }

        // Validation for Email
        if (!email || email.trim() === '') {
            setEmailError('Email cannot be empty');
            isValid = false;
        } else if (!validateEmail(email)) {
            setEmailError('Invalid email address');
            isValid = false;
        } else {
            setEmailError();
        }

        // Validation for Phone Number
        if (!phoneNumber || phoneNumber.trim() === '') {
            setPhoneNumberError('Phone Number cannot be empty');
            isValid = false;
        } else if (!validatePhoneNumber(phoneNumber)) {
            setPhoneNumberError('Invalid phone number');
            isValid = false;
        } else {
            setPhoneNumberError();
        }

        // Validation for how hear
        if (!howHear) {
            setHowHearError('this section cannot be empty');
            isValid = false;
        } else {
            setHowHearError();
        }

        // Validation for cover later
        if (!coverLater || coverLater.trim() === '') {
            setCoverLaterError('Cover later cannot be empty');
            isValid = false;
        } else {
            setCoverLaterError();
        }

        // Validation for cv
        if (!cv) {
            setCVError('CV cannot be empty'); // Set error message
            isValid = false; // Set isValid flag to false
        } else {
            // If cv is not empty
            setCVError(); // Clear any existing error message
        }

        if (isValid) {
            // Proceed with form submission if all fields are valid
            let formData = new FormData();
            formData.append('firstName', firstName);
            formData.append('middleName', middleName);
            formData.append('lastName', lastName);
            formData.append('streetAddress', streetAddress);
            formData.append('streetAddress2', streetAddress2);
            formData.append('city', city);
            formData.append('state', state);
            formData.append('email', email);
            formData.append('phoneNumber', phoneNumber);
            formData.append('linkedIn', linkedIn);
            formData.append('howHear', howHear);
            formData.append('howHearSpecify', howHearSpecify);
            formData.append('coverLater', coverLater);
            formData.append('cv', cv);
            formData.append('positionApplied', id);

            // send data to api
            apply.mutate(formData);
        }
    };

    const validatePhoneNumber = (phoneNumber) => {
        // Regular expression for Afghanistan phone numbers
        const afghanPhoneNumberRegex = /^(\+93|0)?[7][0-9]{8}$/;
        return afghanPhoneNumberRegex.test(phoneNumber);
    };
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    // send data to api using react query
    const apply = useMutation((data) => addObject('apply', data), {
        onSuccess: () => {
            enqueueSnackbar('Successfully applied!', { variant: 'success' });
            navigate('/');
        },
        onError: () => {
            enqueueSnackbar('Occur an error', { variant: 'error' });
        },
    });

    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    };

    return (
        <Box
            sx={{
                backgroundColor: Colors.SECTION_BACKGROUND_COLOR,
                minHeight: '1000px',
            }}
        >
            <MetaTags>
                <title>Afghan cosmos - Apply Job</title>
                <meta name="description" content="Brows our Apply Job" />
            </MetaTags>
            <Container>
                <Box>
                    <Button
                        onClick={() => back()}
                        sx={{ mt: 10, mb: 10 }}
                        startIcon={<KeyboardBackspaceIcon sx={{ mr: 1 }} />}
                    >
                        <Typography>Back</Typography>
                    </Button>
                </Box>
                <Paper sx={{ minHeight: '1200px' }}>
                    <Container>
                        <Grid
                            container
                            columnSpacing={2}
                            rowSpacing={6}
                            component={'form'}
                            onSubmit={handleSubmit}
                            encType="multipart/form-data"
                        >
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                {firstNameError ||
                                lastNameError ||
                                streetAddressError ||
                                cityError ||
                                stateError ||
                                phoneNumberError ||
                                emailError ||
                                howHearError ||
                                coverLaterError ||
                                cvError ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Alert severity="error">
                                            please complete required fields
                                        </Alert>
                                    </Box>
                                ) : (
                                    ''
                                )}
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            First Name *
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            name="firstName"
                                            error={!!firstNameError}
                                            helperText={firstNameError}
                                            onChange={(e) =>
                                                setFirstName(e.target.value)
                                            }
                                            placeholder="First Name"
                                            size="small"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            Middle Name
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            name="middleName"
                                            onChange={(e) =>
                                                setMiddleName(e.target.value)
                                            }
                                            placeholder="Middle Name"
                                            size="small"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            Last Name *
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            name="lastName"
                                            error={!!lastNameError}
                                            helperText={lastNameError}
                                            onChange={(e) =>
                                                setLastName(e.target.value)
                                            }
                                            placeholder="Last Name"
                                            size="small"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            Street Address *
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            name="address1"
                                            error={!!streetAddressError}
                                            helperText={streetAddressError}
                                            onChange={(e) =>
                                                setStreetAddress(e.target.value)
                                            }
                                            placeholder="Address"
                                            size="small"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            Street Address 2
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            name="address2"
                                            onChange={(e) =>
                                                setStreetAddress2(
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Address 2"
                                            size="small"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            City *
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            name="city"
                                            error={!!cityError}
                                            helperText={cityError}
                                            onChange={(e) =>
                                                setCity(e.target.value)
                                            }
                                            placeholder="City"
                                            size="small"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            State *
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            name="state"
                                            error={!!stateError}
                                            helperText={stateError}
                                            onChange={(e) =>
                                                setState(e.target.value)
                                            }
                                            placeholder="Country"
                                            size="small"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            Phone Number *
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            name="phoneNumber"
                                            error={!!phoneNumberError}
                                            helperText={phoneNumberError}
                                            onChange={(e) =>
                                                setPhoneNumber(e.target.value)
                                            }
                                            placeholder="Phone Number"
                                            size="small"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            Email *
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            type="email"
                                            name="email"
                                            error={!!emailError}
                                            helperText={emailError}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            fullWidth
                                            placeholder="Email"
                                            size="small"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            LinkedIn Link
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            name="linkedIn"
                                            onChange={(e) =>
                                                setLinkedIn(e.target.value)
                                            }
                                            placeholder="URL"
                                            size="small"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            How did you Hear *
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            select
                                            name="howHear"
                                            error={!!howHearError}
                                            helperText={howHearError}
                                            onChange={(e) =>
                                                setHowHear(e.target.value)
                                            }
                                            fullWidth
                                            size="small"
                                            value={howHear}
                                        >
                                            {howHearChoice.map((item) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.value}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            If other, Specify
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            name="specify"
                                            onChange={(e) =>
                                                setHowHearSpecify(
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Specify"
                                            size="small"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            Cover Later *
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <ReactQuill
                                            value={coverLater}
                                            onChange={handleCoverLaterChange}
                                            placeholder="Cover Later"
                                            modules={{
                                                toolbar: [
                                                    [
                                                        { header: '1' },
                                                        { header: '2' },
                                                        { font: [] },
                                                    ],
                                                    [{ size: [] }],
                                                    [
                                                        'bold',
                                                        'italic',
                                                        'underline',
                                                        'strike',
                                                        'blockquote',
                                                    ],
                                                    [
                                                        { list: 'ordered' },
                                                        { list: 'bullet' },
                                                        { indent: '-1' },
                                                        { indent: '+1' },
                                                    ],
                                                    ['link', 'image', 'video'],
                                                    ['clean'],
                                                ],
                                            }}
                                            formats={[
                                                'header',
                                                'font',
                                                'size',
                                                'bold',
                                                'italic',
                                                'underline',
                                                'strike',
                                                'blockquote',
                                                'list',
                                                'bullet',
                                                'indent',
                                                'link',
                                                'image',
                                                'video',
                                            ]}
                                            style={{ height: '400px' }}
                                        />

                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: 'red',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {coverLaterError && (
                                                <p>{coverLaterError}</p>
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            component={'label'}
                                            variant="body2"
                                            sx={{ color: 'black' }}
                                        >
                                            CV *
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            type="file"
                                            name="cv"
                                            error={!!cvError}
                                            helperText={cvError}
                                            onChange={(e) =>
                                                setCV(e.target.files[0])
                                            }
                                            size="small"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                {apply.isLoading ? (
                                    <Box>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mb: 2 }}
                                    >
                                        Submit
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </Container>
        </Box>
    );
};
export default JobApply;
